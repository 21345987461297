@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  border: 1px solid $custom-orange;
  border-radius: 8px;

  height: fit-content;

  background: $custom-black;

  padding: 24px 20px;
}

.subtotalWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtotalText {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;

  color: $gray;
}

.subTotalPrice {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
}

.paymentText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: $gray;
}

.paymentCardText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxText {
  color: $custom-gray;

  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;

  a {
    text-decoration: underline;
    color: $custom-orange;
  }
}

.divider {
  width: 100%;
  height: 1px;

  opacity: 0.2;

  background: $custom-orange;
}

.totalWrapper {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
}

.warningMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;

  color: $primary-red;
}
