@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  flex: 1 0 auto;
}

.mainWrapper {
  background: $primary-black;

  background-image: url("../../../assets/images/main_circles.png");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  gap: 100px;

  @include ifmobile {
    gap: 50px;
  }
}
