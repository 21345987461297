@use "../../assets/scss/main.scss" as *;

.container {
  background: $primary-black;
}

.contentWrapper {
  display: grid;
  gap: 24px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 12px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }

  a {
    color: $primary-orange;
  }

  ul > li {
    list-style: disc;
    margin-left: 24px;
  }

  ol > li {
    list-style: decimal;
    margin-left: 20px;
  }
}

.header {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
}

.emailLink {
  white-space: nowrap;
}
