@use "../../../../../assets/scss/main.scss" as *;

.skinsWrapper {
  position: relative;
  width: fit-content;

  @include iftablet {
    margin-left: 20%;
    margin-bottom: 90px;

    order: -1;
  }

  @include ifmobile {
    margin-left: 30%;
  }
}

.mainSkin {
  position: relative;
  z-index: 1;
}

.card {
  position: relative;
}

.card:nth-child(2) {
  position: absolute;
  right: -50%;
  top: 10%;
  transform: rotate(15deg);
  z-index: -1;
}

.card:nth-child(3) {
  position: absolute;
  right: -95%;
  top: 30%;
  transform: rotate(30deg);
  z-index: -2;
}
