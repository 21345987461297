@use "../../assets/scss/main.scss" as *;

.modalContent {
  display: grid;
  gap: 48px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36.4px;

  color: $primary-white;
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;

  color: $gray;
}

.buttonswWrapper {
  display: flex;
  gap: 16px;
}
