@use "../../../../../assets/scss/main.scss" as *;

.skinWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  padding: 16px;

  width: fit-content;

  border-radius: 10px;

  box-shadow: $skin-shadow;
  background: $custom-black;

  cursor: pointer;

  @include ifmobile {
    min-height: auto;
    width: 158px;
  }
}

.uncommon {
  background: $uncommon;

  button::before {
    background-color: $uncommon;
  }

  button::after {
    background-color: $uncommon-dark;
  }
}

.rare {
  background: $rare;

  button::before {
    background-color: $rare;
  }

  button::after {
    background-color: $rare-dark;
  }
}

.mythical {
  background: $mythical;

  button::before {
    background-color: $mythical;
  }

  button::after {
    background-color: $mythical-dark;
  }
}

.legendary {
  background: $legendary;

  button::before {
    background-color: $legendary;
  }

  button::after {
    background-color: $legendary-dark;
  }
}

.ancient {
  background: $ancient;

  button::before {
    background-color: $ancient;
  }

  button::after {
    background-color: $ancient-dark;
  }
}

.exceedinglyRare {
  background: $exceedinglyRare;

  button::before {
    background-color: $exceedinglyRare;
  }

  button::after {
    background-color: $exceedinglyRare-dark;
  }
}

.immortal {
  background: $immortal;

  button::before {
    background-color: $immortal;
  }

  button::after {
    background-color: $immortal-dark;
  }
}

.imageWrapper {
  display: flex;

  background: $custom-blue;

  border-radius: 5px;

  width: 208px;
  height: 164px;

  object-fit: cover;

  img {
    width: 100%;
  }

  @include iftablet {
    width: 168px;
    height: 148px;
  }

  @include ifmobile {
    width: 100%;
    height: 106px;
  }
}

.descriptionWrapper {
  display: grid;
  gap: 16px;
  flex: 1;

  @include iftablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
}

.title {
  max-width: 116px;
}

.description {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 22.22px;
    letter-spacing: -0.03em;

    @include iftablet {
      font-size: 12px;
    }

    @include ifmobile {
      font-size: 9.06px;
      line-height: 14.37px;
    }
  }

  @include ifmobile {
    gap: 5px;
  }
}

.button {
  margin-top: auto;

  @include ifmobile {
    height: 28px;

    border-radius: 5px;

    &::after {
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);

      border-radius: 3px;
    }
  }

  span {
    @include ifmobile {
      font-size: 10.35px;
      font-weight: 700;
      line-height: 16.43px;
      letter-spacing: -0.03em;
    }
  }
}

.introductionSkinWrapper {
  padding: 20px;

  gap: 10px;

  cursor: auto;

  .imageWrapper {
    width: 268px;
    height: 206px;

    @include ifdesktopM {
      width: 220px;
      height: 100%;
    }

    @include ifmobile {
      width: 100%;
      height: auto;
    }
  }

  .description {
    p {
      font-size: 17.65px;
      font-weight: 700;
      line-height: 28.01px;
      letter-spacing: -0.03em;

      @include iftablet {
        font-size: 14px;
      }

      @include ifmobile {
        font-size: 5.82px;
        line-height: 9.23px;
      }
    }
  }

  button {
    @include ifmobile {
      height: 18px;
      padding: 3px;

      border-radius: 3px;

      span {
        font-size: 6.65px;
        font-weight: 700;
        line-height: 10.55px;
        letter-spacing: -0.03em;
      }

      &::after {
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        border-radius: 3px;
      }
    }
  }

  @include ifmobile {
    padding: 12px;

    width: 101px;
  }

  .descriptionWrapper {
    gap: 20px;

    @include ifmobile {
      gap: 8px;

      min-height: auto;
    }
  }
}

.price {
  white-space: nowrap;
}

.selectedSkin {
  background: $brown-active;
  color: $primary-white;

  fill: $primary-white;
}
