@use "../assets/scss/main.scss" as *;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-main;
}

html,
body {
  max-width: 100vw;

  scrollbar-gutter: stable;
  -webkit-scrollbar-gutter: stable;
}

ul,
li {
  list-style: none;
}

a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

button {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  border: none;
  color: inherit;
  font-size: inherit;
}

p {
  color: $primary-white;
}

h1 {
  font-size: 96px;

  @include ifdesktopM {
    font-size: 72px;
  }

  @include iftablet {
    font-size: 64px;
  }
}

h2 {
  font-size: 46px;

  @include ifdesktopM {
    font-size: 36px;
  }

  @include iftablet {
    font-size: 32px;
  }

  @include ifmobile {
    font-size: 28px;
  }
}

h3 {
  font-size: 36px;

  @include ifdesktopM {
    font-size: 36px;
  }

  @include iftablet {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;

  @include ifdesktopM {
    font-size: 20px;
  }

  @include iftablet {
    font-size: 18px;
  }
}

h5 {
  font-size: 20px;

  @include ifdesktopM {
    font-size: 18px;
  }

  @include iftablet {
    font-size: 16px;
  }
}

h6 {
  font-size: 16px;

  @include iftablet {
    font-size: 16px;
  }
}

.Toastify__toast-container {
  .Toastify__toast-body {
    align-items: flex-start;
  }

  .Toastify__toast {
    background-color: $primary-black;

    &--error {
      border-left: 2px solid $custom-red;

      .Toastify__toast-icon svg {
        fill: $custom-red;
      }
    }

    &--success {
      border-left: 2px solid $custom-secondary-green;

      .Toastify__toast-icon svg {
        fill: $custom-secondary-green;
      }
    }

    &--info {
      border-left: 2px solid $custom-yellow;

      .Toastify__toast-icon svg {
        fill: $custom-yellow;
      }
    }
  }

  .Toastify__close-button {
    align-self: center;
    opacity: 1;

    &:hover {
      svg {
        fill: $white-dark;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      transition: 0.3s fill;

      fill: $custom-gray;
    }
  }
}
