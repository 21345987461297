@use "../../../assets/scss/main.scss" as *;

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 32px 0;

  height: 100%;
}

.container {
  display: grid;
  gap: 24px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
