@use "../../../../../assets/scss/main.scss" as *;

.aboutUsItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 8px;
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 38.09px;

    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25.39px;

    padding: 10px;
  }
}
