@use "../../assets/scss/main.scss" as *;

.mainSection {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainWrapper {
  display: grid;
  grid-template-columns: 15% 85%;

  flex: 1 0 auto;

  @include ifmobile {
    display: flex;
    grid-template-columns: 15% 85%;
    flex-direction: column;
  }
}

.outletWrapper {
  background: $primary-black;
  padding: 74px 110px;

  @include ifmobile {
    padding: 20px 40px;

    flex: 1 0 auto;
  }
}
