@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;
}

.title {
  color: $primary-white;
}

.tableWrapper {
  display: grid;
  position: relative;

  @include ifmobile {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
  }
}

.tableRowHead {
  border-radius: 14px;
  background: $primary-white;

  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  color: $primary-black;
}

.tableRow {
  display: flex;
  flex-direction: column;

  width: fit-content;

  border-bottom: 2px solid $primary-white;
}

.tableRowBody {
  font-size: 16px;
  text-decoration: none;

  color: $primary-white;

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  img {
    width: 100%;
    height: 75px;
    object-fit: cover;
  }
}

.tableRowHead,
.tableRowBody {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1.5fr 1fr;
  align-items: center;
  gap: 8px;

  padding: 8px 16px;

  min-width: 500px;

  border-bottom: 1px solid $primary-black;
  transition: background-color 0.3s ease;

  @include ifmobile {
    width: fit-content;
    grid-template-columns: 50px 50px 100px 50px 50px 50px 100px 50px;
    gap: 20px;
  }
}

.imageWrapper {
  display: flex;
}
