@use "../../assets/scss/main.scss" as *;

.aside {
  background: $primary-black;

  padding: 80px 10%;

  border-right: 2px solid $white;

  @include ifmobile {
    border: 0;

    padding: 10px 10%;

    border-bottom: 2px solid $white;

    height: fit-content;
  }
}

.linksWrapper {
  display: grid;
  gap: 16px;

  text-align: end;

  @include ifmobile {
    display: flex;
    align-items: center;
  }
}

.link {
  color: rgba(255, 255, 255, 0.6);

  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;

  @include ifmobile {
    display: flex;

    font-size: 14px;
    text-align: center;

    transition-duration: 0.25s;
  }
}

.activeLink {
  color: $white;
  font-weight: 500;

  font-family: Inter;
  font-size: 18px;
  line-height: 120%;

  @include ifmobile {
    display: flex;

    font-size: 14px;
    text-align: center;

    transition-duration: 0.25s;
  }
}

.menuToggle {
  opacity: 0;
}

.menuToggle:checked + .menuBtn > span {
  transform: rotate(45deg);
}

.menuToggle:checked + .menuBtn > span::before {
  top: 0;
  transform: rotate(0deg);
}

.menuToggle:checked + .menuBtn > span::after {
  top: 0;
  transform: rotate(90deg);
}

.menuToggle:checked ~ .linksWrapper {
  left: 0;
}

.menuBtn {
  @include ifmobile {
    display: block;
    position: fixed;
    top: 120px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 3;
  }
}

.menuBtn > span,
.menuBtn > span::before,
.menuBtn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $white;
  transition-duration: 0.25s;
}

.menuBtn > span::before {
  content: "";
  top: -8px;
}

.menuBtn > span::after {
  content: "";
  top: 8px;
}
