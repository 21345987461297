@use "../../../../assets/scss/main.scss" as *;

.contentWrapper {
  display: grid;
  gap: 24px;

  position: relative;

  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;

  padding: 0 12px;

  max-width: $main-container-width;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: $mobile-container-width;
    gap: 12px;
  }

  @include ifmobile {
    max-width: 500px;
  }
}

.searchWrapper {
  display: flex;
  gap: 16px;

  width: 40%;

  @include iftablet {
    width: 100%;
  }
}

.menuToggle {
  display: none;
}

.menuToggle:checked ~ .form {
  left: 0;

  justify-content: center;
  align-items: center;

  overflow: auto;

  padding-bottom: 32px;
  padding-top: 32px;
}

.menuToggle:checked ~ .menuBtn {
  position: fixed;

  top: 20px;
}

.menuBtn {
  display: none;

  transition-duration: 0.25s;

  @include ifmobile {
    display: block;

    position: absolute;
    top: 0;
    right: 12px;

    width: 26px;
    height: 26px;

    cursor: pointer;

    z-index: 2;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 44px;

  @include ifmobile {
    display: flex;
    flex-direction: column;
    gap: 48px;

    position: fixed;

    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 80px 24px;

    background: $primary-black;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

    transition-duration: 0.25s;
    z-index: 1;
  }
}

.filterWrapper {
  display: flex;
  align-items: flex-end;
  gap: 16px;

  @include iftablet {
    flex-direction: column;
  }

  @include ifmobile {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.filter {
  display: grid;
  gap: 8px;

  position: relative;

  width: 22%;

  p {
    color: $white;

    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    letter-spacing: -0.03em;
  }

  @include iftablet {
    width: 100%;
  }
}

.inputsWrapper {
  display: flex;
  gap: 8px;
}

.warningMessage {
  position: absolute;

  justify-self: center;

  p {
    font-size: 14px;

    color: $red;

    text-align: center;
  }

  bottom: -42px;
}

.resetButton {
  span {
    color: $primary-orange;
  }

  @include ifmobile {
    width: 100%;
  }
}

.price {
  width: 100%;
}

.skinsWrapper {
  display: grid;
  gap: 12px;

  h6 {
    color: $custom-green;

    font-size: 16px;
    font-weight: 700;
    line-height: 18.83px;
    letter-spacing: -0.03em;
  }
}

.skinsWithPagination {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.mainWrapper {
  display: flex;
  justify-content: center;
  gap: 51px;

  @include ifdesktopM {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.skins {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include ifmobile {
    gap: 24px;
  }
}

.mainSkins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.asideSkins {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.asideSkinsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @include ifdesktopM {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: stretch;
  }
}

.rareTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.25px;
  letter-spacing: -0.03em;

  text-transform: uppercase;
}

.emptyBlock {
  display: flex;
  flex-direction: column;

  padding: 142px 0;
  margin: 0 auto;

  position: relative;

  @include ifmobile {
    padding: 64px 0;
  }
}

.emptyImage {
  position: absolute;

  top: 50px;
  left: -65px;

  @include iftablet {
    width: 500px;
  }

  @include ifmobile {
    top: 15px;
    left: -60px;

    width: 300px;
  }
}

.emptyTitle {
  font-weight: 500;
  line-height: 42.37px;
  letter-spacing: -0.03em;

  color: $custom-green;

  max-width: 320px;

  z-index: 0;

  @include iftablet {
    line-height: 32px;
  }

  @include ifmobile {
    line-height: 20px;

    font-size: 14px;
  }
}
