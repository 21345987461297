@use "../../assets/scss/main.scss" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  background: $primary-black;

  z-index: 3;

  picture {
    display: flex;
  }

  img {
    cursor: pointer;
  }
}

.navigationWrapper {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 12px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.articleNavigationWrapper {
  margin-bottom: 0;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;

  padding: 18px 0;
}

.logoAndNavigationWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  width: 100%;
}

.logo {
  display: flex;

  cursor: pointer;

  z-index: 3;
}

.menuToggle {
  display: none;
}

.menuToggle:checked + .menuBtn > span {
  transform: rotate(45deg);
}

.menuToggle:checked + .menuBtn > span::before {
  top: 0;
  transform: rotate(0deg);
}

.menuToggle:checked + .menuBtn > span::after {
  top: 0;
  transform: rotate(90deg);
}

.menuToggle:checked ~ .navigation {
  left: 0;

  justify-content: center;
  align-items: center;
}

.menuBtn {
  display: none;

  transition-duration: 0.25s;

  @include ifmobile {
    display: block;

    position: absolute;
    top: 34px;
    right: 12px;

    width: 26px;
    height: 26px;

    cursor: pointer;

    z-index: 3;
  }
}

.menuBtn > span,
.menuBtn > span::before,
.menuBtn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: $white;
  transition-duration: 0.25s;
}

.menuBtn > span::before {
  content: "";
  top: -8px;
}

.menuBtn > span::after {
  content: "";
  top: 8px;
}

.navigation {
  margin-right: 24px;

  display: flex;
  justify-content: flex-end;

  width: 100%;

  @include ifmobile {
    display: flex;
    flex-direction: column;
    gap: 48px;

    position: fixed;

    margin-right: 0;

    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 80px 24px;

    background: $primary-black;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

    transition-duration: 0.25s;
    z-index: 2;
  }
}

.navigationLinks {
  display: flex;
  align-items: center;
  gap: 24px;

  margin-right: 24px;

  text-wrap: nowrap;

  font-family: $font-main;

  @include iftablet {
    gap: 8px;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 8px;
    width: fit-content;

    margin-right: 0;
  }
}

.mobileNavigationLinks {
  display: none;

  @include ifmobile {
    display: flex;
  }
}

.link {
  text-wrap: nowrap;
  color: $gray;

  font-size: 16px;
  font-weight: 700;
  line-height: 25.39px;

  @include ifmobile {
    font-size: 18px;
    font-weight: 500;

    display: block;
    padding: 6px 12px;
    transition-duration: 0.25s;

    width: fit-content;
  }

  &:is(:hover, :active) {
    color: $primary-white;
  }
}

.activeLink {
  color: $primary-white;
}

.inputWrapper {
  @include ifmobile {
    order: 1;
  }
}

.mobileButtonsWrapper {
  display: none;
  gap: 24px;

  width: 100%;

  @include ifmobile {
    display: flex;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 24px;

  @include ifmobile {
    display: none;
  }
}

.userWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  @include iftablet {
    gap: 8px;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 24px;

  @include ifdesktopM {
    gap: 12px;
    width: 100%;
    justify-content: space-between;
  }
}

.mobileUser {
  display: none;

  @include ifmobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
  }
}

.pictureWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  img {
    border-radius: 50%;

    background: $black;

    width: 24px;
    height: 24px;

    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    color: $primary-white;
  }

  &:is(:hover, :active) img {
    opacity: 0.6;
  }
}

.userInfo {
  position: relative;

  display: flex;
  gap: 8px;

  padding: 9px 18px;

  p {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.22px;
    letter-spacing: -0.03em;

    color: $green-light;
  }

  @include iftablet {
    padding: 0;
  }

  @include ifmobile {
    display: none;
  }
}

.mobileUserInfo {
  display: none;

  position: relative;

  gap: 8px;

  padding: 9px 18px;

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.22px;
    letter-spacing: -0.03em;

    color: $green-light;
  }

  @include iftablet {
    padding: 0;
  }

  @include ifmobile {
    display: flex;

    p {
      white-space: nowrap;
    }
  }
}

.arrowIcon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.arrowIcon.activeIcon {
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
}

.dropDownMenu {
  position: absolute;
  top: 55px;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 5%;

  border-radius: 8px;
  border: 1px solid $orange;

  text-align: center;

  width: 180px;

  background: $custom-black;
  z-index: 1;

  .link {
    color: rgba(255, 255, 255, 0.6);

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    padding: 6px 24px;

    &:is(:hover, :active) {
      color: $white;
    }
  }
}

.cartIcon {
  display: flex;

  position: relative;

  cursor: pointer;

  z-index: 3;

  @include ifmobile {
    margin-right: 52px;
  }
}

.cartAmount {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  right: -6px;
  top: -4px;

  width: 16px;
  height: 16px;

  border-radius: 50%;

  background: $green-light;

  p {
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;

    color: $black;
  }
}

.buttonIcon {
  display: flex;
}

.logoutButton {
  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    color: $black;
  }
}
