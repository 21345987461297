@use "../../assets/scss/main.scss" as *;

.modalContent {
  display: grid;
  gap: 48px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  padding: 0 60px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36.4px;

  color: $primary-white;
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;

  color: $gray;
}

.inputWrapper {
  display: grid;
  gap: 8px;

  width: 100%;

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;

    color: $primary-white;
  }
}

.disabledInput {
  p {
    opacity: 0.4;
  }
}

.input {
  display: flex;
  gap: 16px;

  position: relative;
}

.icon {
  position: absolute;
  left: -34px;
  bottom: 10px;
}

.buttonswWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.button {
  width: 160px;
  min-width: 80px;
}
