@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.info {
  display: flex;
  gap: 8px;
}

.imageWrapper {
  padding: 2px;

  background: $custom-blue;

  img {
    width: 53px;
  }
}

.title {
  font-size: 12px;
  font-weight: 700;
  line-height: 19.04px;
  letter-spacing: -0.03em;

  color: $gray;
}

.price {
  font-size: 12px;
  font-weight: 700;
  line-height: 19.04px;
  letter-spacing: -0.03em;

  color: $primary-white;

  white-space: nowrap;
}
