@use "../../../../assets/scss/main.scss" as *;

.contentWrapper {
  display: grid;
  gap: 64px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  h2 {
    text-align: center;

    font-weight: 700;
    line-height: 54.14px;
    letter-spacing: -0.03em;
  }

  @include ifdesktopM {
    max-width: $tablet-container-width;
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: $mobile-container-width;
    gap: 24px;
  }

  @include ifmobile {
    max-width: 500px;
  }
}

.aboutUsDataWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 37px;

  @include ifmobile {
    grid-template-columns: 1fr;
  }
}
