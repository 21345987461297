@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 100vw;
  height: 100vh;

  color: $black;

  h3 {
    padding-right: 16px;
    border-right: 3px solid $black;
  }
}
