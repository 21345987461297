@use "../../assets/scss/main.scss" as *;

.footer {
  background: $primary-black;
}

.contentWrapper {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 48px 12px;
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include ifdesktopM {
    max-width: $tablet-container-width;

    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    margin-top: 32px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.info {
  display: flex;
  gap: 144px;

  @include iftablet {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}

.footerItemWrapper {
  display: grid;
  gap: 32px;

  @include ifmobile {
    flex-wrap: wrap;
    justify-content: start;
    gap: 40px;

    text-align: start;
  }
}

.contactWrapper {
  display: grid;
  gap: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.3px;

  text-transform: uppercase;

  color: $primary-white;

  @include iftablet {
    text-align: center;
  }

  @include ifmobile {
    font-weight: 600;

    margin-bottom: 8px;
  }
}

.footerItems {
  display: flex;

  gap: 16px;

  @include iftablet {
    justify-content: center;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 0;
  }
}

.footerItem {
  display: grid;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.4px;

  @include ifmobile {
    flex-wrap: wrap;

    text-align: center;
  }
}

.navigationWrapper {
  display: grid;
  gap: 70px;

  @include iftablet {
    gap: 32px;
    order: -1;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include ifmobile {
    gap: 12px;
  }
}

.logoWrapper {
  display: flex;

  cursor: pointer;
}

.links {
  display: flex;
  gap: 16px;
}

.link {
  color: $primary-white;

  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  letter-spacing: -0.03em;
}

.visaImage {
  width: 220px;
  height: 26px;
}

.credentials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
