@use "../../../assets/scss/main.scss" as *;

.articleSection {
  flex: 1 0 auto;

  background: $primary-black;

  background-image: url("../../../assets/images/main_circles.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentWrapper {
  display: grid;
  gap: 32px;

  box-sizing: content-box;
  padding: 32px 12px 0;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    max-width: 500px;
  }
}

.mainWrapper {
  display: grid;
  gap: 100px;

  padding: 0 224px;

  color: $primary-white;

  h1 {
    font-size: 64px;
    text-align: center;
  }

  @include iftablet {
    padding: 0;
  }
}

.titleWrapper {
  display: grid;
  gap: 35px;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  letter-spacing: -0.03em;
  text-align: center;

  color: $primary-white;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 14px;

  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.03em;
  text-align: left;

  color: $primary-white;

  img {
    width: 100%;
    height: auto;

    object-fit: cover;
  }
}
