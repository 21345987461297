@use "../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel-heading,
    .panel-body {
      padding: 16px;
    }
  }

  @include ifmobile {
    top: 115px;
    right: auto;
  }
}

.form {
  display: grid;
  gap: 32px;
}

.textWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: 500;

    color: $primary-white;
  }
}

.buttonswWrapper {
  display: flex;
  gap: 16px;
}

.cancelButton {
  border: 1px solid $white;
}
