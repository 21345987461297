@use "../../assets/scss/main.scss" as *;

:global {
  .pure-modal-backdrop {
    background-color: rgba(0, 0, 0, 0);

    margin-left: auto;
    margin-right: auto;

    max-width: $main-container-width;
    width: 100%;

    overflow-y: auto;

    @include ifdesktopM {
      max-width: $tablet-container-width;
      box-sizing: border-box;
    }

    @include iftablet {
      max-width: $mobile-container-width;
      gap: 24px;
    }

    @include ifmobile {
      max-width: 500px;
    }

    .pure-modal {
      @include ifmobile {
        width: 90%;
      }
    }
  }
}

.modal {
  padding-top: 8px;
  padding-bottom: 16px;

  cursor: auto;

  :global {
    .panel {
      border-radius: 8px;
      border: 1px solid $custom-orange;

      box-shadow: $modal-shadow;
      overflow: hidden;
    }

    .panel-heading {
      background: $custom-black;
    }

    .close {
      background: none;
      top: 24px;
      right: 16px;

      @include ifmobile {
        right: 4px;
      }
    }

    .panel-title {
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;

      padding: 0;

      @include ifmobile {
        font-size: 28px;
        font-weight: 600;
        line-height: 50px;
      }
    }

    .panel-body {
      background: $custom-black;

      padding: 24px 32px;
    }
  }
}

.header {
  position: relative;
}

.circleWrapper {
  position: relative;

  overflow: hidden;

  padding: 36px 44px;
}

.violetCircle {
  position: absolute;

  width: 40%;
  height: 250%;

  filter: blur(100px);

  border-radius: 30%;

  bottom: -350px;
  right: 10%;

  background: $black;

  transform: rotate(100deg);
}

.redCircle {
  position: absolute;
  width: 30%;
  height: 200%;

  filter: blur(100px);

  border-radius: 50%;

  bottom: -150%;
  left: 0;

  background: $black;
}

.cartModal {
  :global {
    .close {
      display: none;
    }
  }
}
