@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  gap: 24px;

  background: $custom-black;

  border: 1px solid $custom-orange;
  border-radius: 8px;

  padding: 26px 32px;
  height: fit-content;

  @include ifmobile {
    flex-direction: column;
  }
}

.productWrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  width: 100%;

  @include ifmobile {
    flex-direction: column;
  }
}

.productInfo {
  display: flex;
  gap: 16px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  background: $custom-blue;

  border-radius: 8px;

  padding: 8px;

  img {
    width: 106px;
    height: 86px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28.57px;
  letter-spacing: -0.03em;

  color: $primary-white;
}

.quantityWrapper {
  display: flex;
  align-items: center;
  gap: 9px;
}

.quantityText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: $custom-gray;
}

.counterWrapper {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  align-items: center;

  border: 1px solid $custom-orange;
  border-radius: 8px;

  color: $custom-orange;
}

.quantity {
  padding: 12px 20px;

  width: 50px;

  text-wrap: nowrap;
}

.marksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-left: 1px solid $custom-orange;

  width: 100%;
}

.plus {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2px 6px;

  width: 100%;

  border-bottom: 1px solid $custom-orange;

  cursor: pointer;
}

.minus {
  cursor: pointer;
  padding: 4px;
}

.price {
  font-size: 20px;
  font-weight: 700;
  line-height: 31.74px;
  letter-spacing: -0.03em;

  opacity: 0.7;

  white-space: nowrap;
}

.totalPriceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  max-width: 130px;
  min-width: 130px;

  p {
    font-size: 32px;
    font-weight: 700;
    line-height: 44.8px;

    text-wrap: nowrap;
  }
}

.removeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  color: $primary-red;

  cursor: pointer;
}

.divider {
  width: 1px;

  opacity: 0.2;

  background: $custom-orange;

  @include ifmobile {
    width: 100%;
    height: 1px;
  }
}
