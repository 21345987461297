@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  text-align: center;

  padding: 64px;

  border-radius: 8px;

  background: $custom-black;

  max-height: 616px;
}

.title {
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -0.03em;

  text-align: left;

  color: $primary-white;

  @include ifmobile {
    font-size: 40px;
    line-height: 60px;
  }
}

.titleWrapper {
  display: flex;
  gap: 152px;
  overflow: hidden;

  @include iftablet {
    gap: 88px;
  }

  @include ifmobile {
    gap: 44px;
  }
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.03em;

  text-align: left;

  color: $primary-white;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p > img {
    height: auto;
  }

  @include ifmobile {
    font-size: 14px;
    line-height: 18px;
  }
}
