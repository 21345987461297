@use "../../../../assets/scss/main.scss" as *;

.contentWrapper {
  display: grid;
  gap: 64px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: calc($main-container-width - 288px);
  width: 100%;

  padding: 0 15px;

  h2 {
    text-align: center;

    font-weight: 700;
    line-height: 54.14px;
    letter-spacing: -0.03em;
  }

  @include ifdesktopM {
    max-width: calc($tablet-container-width - 180px);
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: calc($mobile-container-width - 80px);
    gap: 24px;
  }

  @include ifmobile {
    max-width: 500px;
  }
}

.carousel {
  overflow: hidden;
}
