@use "../../../../../assets/scss/main.scss" as *;

.uncommon {
  & :global {
    .panel-body {
      background: $uncommon;
    }

    button::before {
      background-color: $uncommon;
    }

    button::after {
      background-color: $uncommon-dark;
    }
  }
}

.rare {
  & :global {
    .panel-body {
      background: $rare;
    }

    button::before {
      background-color: $rare;
    }

    button::after {
      background-color: $rare-dark;
    }
  }
}

.mythical {
  & :global {
    .panel-body {
      background: $mythical;
    }

    button::before {
      background-color: $mythical;
    }

    button::after {
      background-color: $mythical-dark;
    }
  }
}

.legendary {
  & :global {
    .panel-body {
      background: $legendary;
    }

    button::before {
      background-color: $legendary;
    }

    button::after {
      background-color: $legendary-dark;
    }
  }
}

.ancient {
  & :global {
    .panel-body {
      background: $ancient;
    }

    button::before {
      background-color: $ancient;
    }

    button::after {
      background-color: $ancient-dark;
    }
  }
}

.exceedinglyRare {
  & :global {
    .panel-body {
      background: $exceedinglyRare;
    }

    button::before {
      background-color: $exceedinglyRare;
    }

    button::after {
      background-color: $exceedinglyRare-dark;
    }
  }
}

.immortal {
  & :global {
    .panel-body {
      background: $immortal;
    }

    button::before {
      background-color: $immortal;
    }

    button::after {
      background-color: $immortal-dark;
    }
  }
}

.modalContent {
  display: flex;
  gap: 16px;

  @include ifmobile {
    flex-direction: column;
  }
}

.imageWrapper {
  width: 400px;
  height: 300px;

  background: $custom-blue;

  border-radius: 5px;

  img {
    width: 100%;
  }

  @include ifdesktopM {
    width: 320px;
    height: fit-content;
  }

  @include ifmobile {
    width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 24px;

  flex: 1;

  word-break: auto-phrase;

  @include ifdesktopM {
    gap: 8px;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.03em;

  word-wrap: break-word;

  padding-right: 24px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  @include ifdesktopM {
    gap: 16px;
  }
}

.typeWrapper {
  display: flex;
  justify-content: space-between;
}

.type {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.price {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.13px;
  letter-spacing: -0.03em;

  @include ifmobile {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
}

.description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.descriptionTitle {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
}

.descriptionValue {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}
