@use "../../assets/scss/main.scss" as *;

.container {
  position: relative;
  z-index: 1;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 47px;
  height: 44px;

  border: 2px solid $gray;
  border-radius: 8px;

  color: $primary-white;

  font-size: 16px;
  font-weight: 700;
  line-height: 25.39px;
  letter-spacing: -0.03em;

  @include ifmobile {
    width: 37px;
    height: 34px;

    font-size: 14px;
  }
}

.pageLink {
  opacity: 0.4;
}

.activeLink {
  border: 2px solid $gray;

  opacity: 1;

  font-size: 16px;
  font-weight: 700;
  line-height: 25.39px;
  letter-spacing: -0.03em;
}

.breakLabel {
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;

  width: 47px;
  height: 44px;

  border: 2px solid $gray;

  border-radius: 8px;

  color: $primary-white;

  font-size: 16px;
  font-weight: 700;
  line-height: 25.39px;
  letter-spacing: -0.03em;

  cursor: pointer;

  @include ifmobile {
    width: 37px;
    height: 34px;

    font-size: 14px;
  }
}

.activeLinkClassName {
  color: $primary-white;
}

.adminActiveLinkClassName {
  background: $white;

  color: $black;
}

.paginationDescription {
  text-align: center;

  color: $black;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
