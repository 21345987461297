@use "../../../assets/scss/main.scss" as *;

.balanceSection {
  display: grid;
  gap: 48px;

  width: 50%;

  @include iftablet {
    width: 100%;
  }
}

.balanceInfo {
  display: flex;
  align-items: center;
  gap: 24px;

  p {
    color: $white;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  h6 {
    color: $white;

    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 52px;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}
