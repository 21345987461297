@use "../../assets/scss/main.scss" as *;

.customToast {
  h4 {
    transform: translateY(-2px);
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: $white-dark;
  }

  p {
    font-size: 14px;
    color: $white-dark;
    opacity: 0.6;
    line-height: 1.5;
  }
}
