@use "../../../../assets/scss/main.scss" as *;

.introductionSection {
  background: $primary-black;

  background-image: url("../../../../assets/images/header_circles.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: 50%;

  padding: 168px 0;

  @include ifmobile {
    padding: 0;
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 58px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  padding: 0 72px;
  margin-bottom: 100px;

  max-width: $main-container-width;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;

    padding: 0 12px;

    gap: 24px;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    grid-template-columns: 1fr;
  }

  @include ifmobile {
    max-width: 500px;

    margin-bottom: 72px;
    margin-top: 35px;
  }
}

.comment {
  color: $custom-green;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.03em;

  line-height: 18.83px;
}

.infoWrapper {
  display: grid;
  gap: 18px;
}

.descriptionWrapper {
  display: grid;
  gap: 28px;
}

.description {
  display: grid;
  gap: 32px;

  h2 {
    font-size: 46px;
    font-weight: 400;
    line-height: 54.14px;
    letter-spacing: -0.03em;

    @include ifmobile {
      font-size: 32px;
      font-weight: 400;
      line-height: 37.66px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    @include ifmobile {
      line-height: 20.8px;
    }
  }
}

.buttonWrapper {
  @include ifmobile {
    button {
      width: 100%;
    }
  }
}
