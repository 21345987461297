@use "../../assets/scss/main.scss" as *;

.checkbox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  height: 12px;
  width: 12px;

  opacity: 0;
  margin: 0;

  &:focus:invalid ~ .label::before {
    box-shadow: $checkbox-shadow;
  }
}

.disabled {
  opacity: 0.6;

  .label {
    cursor: default;
  }
}

.custom-checkbox {
  position: relative;

  border-radius: 4px;
  border: 1px solid $gray;
}

.label {
  display: flex;
  align-items: center;

  position: relative;
  overflow: hidden;

  cursor: pointer;
  padding: 1px; /* because of input box-shadow 1px */
}

.label::before {
  content: "";
  display: block;
  position: relative;

  width: 24px;
  height: 24px;

  flex-shrink: 0;

  box-sizing: border-box;
}

.label.checkbox-input-top {
  align-items: flex-start;
  &:before {
    top: 4px;
  }
}

.label::after {
  width: 10px;
  height: 6px;

  position: absolute;
  content: "";

  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: $primary-white;

  transform: rotate(-45deg) translate3d(0, 0, 0);
  left: 3px;
  top: 4px;
  transform-origin: center center;

  display: none;
  margin: auto;
  box-sizing: border-box;
}

.checkbox:checked ~ .label::before {
  background-color: $custom-orange;

  border-radius: 7px;
  border: 4px solid $custom-black;
}
