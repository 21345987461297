@use "../../assets/scss/main.scss" as *;

.button {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  min-width: 66px;

  border-radius: 8px;

  cursor: pointer;

  padding: 9px 18px;

  opacity: 1;

  transition: opacity 0.2s ease;

  white-space: nowrap;

  height: 44px;
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button span {
  font-size: 16px;
  font-weight: 700;
  line-height: 25.39px;

  display: flex;
  align-items: center;
  gap: 8px;
}

.small {
  line-height: 28px;
  padding: 4px 24px;
  width: fit-content;
}

.small span {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 13px;
}

.medium {
  min-width: 66px;
}

.full {
  width: 100%;
}

.auto {
  width: auto;
}

.admin {
  padding: 4px 16px;
}

.default {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;

  border: 1px solid $orange;
  background: transparent;

  color: $orange;

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    @include iftablet {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &:is(:hover, :active) {
    background: $brown-hover;
  }

  @include ifmobile {
    padding: 4px;
    height: 44px;
  }
}

.outline {
  background: transparent;

  border: 2px solid $gray;

  span {
    color: $primary-white;
  }
}

.secondary {
  background: $primary-white;
  border: 1px solid $primary-white;

  min-width: 130px;

  span {
    color: $black;
  }
}

.avatar {
  padding: 4px 24px;

  background: $black;

  span {
    color: $white;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}

.gradients {
  border: none;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    width: 200%;
    height: 700%;

    background-color: $custom-black;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: $button-border-gradient;

    animation: rotate 3s linear infinite;
  }

  &::after {
    content: "";

    z-index: -1;
    position: absolute;

    left: 2px;
    top: 2px;

    width: calc(100% - 4px);
    height: calc(100% - 4px);

    background: #1a1b20;

    border-radius: 5px;

    transition: all 0.2s linear;
  }

  &:hover::after {
    background: $brown-hover;
  }

  &:active::after {
    background: $brown-active;
  }

  &:active {
    color: $primary-white;
  }

  &:active path {
    fill: $primary-white;
  }

  @include ifmobile {
    padding: 4px;
    height: 44px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
