@use "../../../../../assets/scss/main.scss" as *;

.articleContainer {
  display: grid;
  gap: 8px;

  width: 100%;

  background: $custom-black;

  border-radius: 16px;

  padding: 16px;

  transition: all 0.2s linear;

  cursor: pointer;

  max-height: 264px;
  overflow: hidden;

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 38.09px;
    letter-spacing: -0.03em;

    color: $primary-white;
  }

  &:hover {
    transform: scale(1.12);
  }
}

.articleContent {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.03em;

  color: #fcfcfc;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    display: none;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  p {
    padding: 9.5px 3.5px;

    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    color: $custom-orange;

    cursor: pointer;
  }
}

.icon {
  display: flex;
  justify-content: flex-end;
}
