@use "../../../assets/scss/main.scss" as *;

.formWrapper {
  display: grid;
  gap: 32px;

  width: 60%;

  @include iftablet {
    width: 100%;
  }
}

.description {
  display: grid;
  gap: 8px;

  p {
    color: $white;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}

.inputWrapper {
  display: grid;
  gap: 12px;

  width: 100%;

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    color: $primary-white;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;

    color: $custom-gray;
  }
}

.emailText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.disabledInput {
  p {
    opacity: 0.4;
  }
}

.profileBlock {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.input {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  position: relative;

  @include ifmobile {
    flex-direction: column;
  }
}

.icon {
  position: absolute;
  left: -34px;
  bottom: 10px;
}

.fileWrapper {
  display: grid;
  gap: 8px;

  & > p {
    color: $white;

    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }
}

.pictureWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 50px;
  }
}

.deleteIcon {
  cursor: pointer;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $white;

    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    opacity: 0.7;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $white;

    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
}

.timezone {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.profileFooter {
  justify-self: flex-end;

  margin-top: 24px;
}
