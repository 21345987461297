@use "../../../assets/scss/main.scss" as *;

.formWrapper {
  display: grid;
  gap: 32px;

  width: 50%;

  margin: 0 auto;

  @include ifmobile {
    width: 100%;
  }
}

.loader {
  justify-self: center;
}

.selectWrapper {
  display: grid;
  gap: 24px;
}

.buttonWrapper {
  justify-self: center;
}
