@use "../../assets/scss/main.scss" as *;

.modal {
  position: absolute;
  top: 65px;
  right: 140px;

  z-index: 100;

  :global {
    .panel-body {
      padding: 16px;
    }
  }

  @include ifmobile {
    top: 115px;
    right: auto;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: grid;
  gap: 20px;
}

.skinInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 10px;

  border-bottom: 1px solid $gray-dark;
}

.pricesWrapper {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $black;
  }

  text-wrap: nowrap;
}

.totalWrapper {
  display: flex;
  justify-content: space-between;

  padding-top: 10px;

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 28.57px;
    letter-spacing: -0.03em;

    color: $primary-white;
  }
}

.emptyCartText {
  text-align: center;

  color: $primary-white;
}

.emptyCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 14px 50px;
  }
}
