@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 100px;

  padding-top: 100px;

  flex: 1 0 auto;

  background: $primary-black;

  background-repeat: no-repeat;
  background-size: cover;

  @include iftablet {
    gap: 100px;
  }

  @include ifmobile {
    gap: 32px;
  }
}
