@use "../../../assets/scss/main.scss" as *;

.cartSection {
  flex: 1 0 auto;

  background: $primary-black;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  box-sizing: content-box;
  padding: 0 12px;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;
  height: 100%;

  h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 50.4px;

    color: $primary-white;
  }

  @include ifdesktopM {
    max-width: $tablet-container-width;
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    max-width: 500px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.cartWrapper {
  display: grid;
  grid-template-columns: 1.7fr 0.7fr;
  gap: 46px;

  @include iftablet {
    grid-template-columns: 1fr;
  }
}

.cartProducts {
  display: grid;
  gap: 30px;
}

.emptyCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  text-align: center;
}
