@use "../../../../assets/scss/main.scss" as *;

.contentWrapper {
  display: grid;
  gap: 64px;

  box-sizing: content-box;

  padding: 0 12px;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  h2 {
    text-align: center;

    font-weight: 700;
    line-height: 54.14px;
    letter-spacing: -0.03em;
  }

  @include ifdesktopM {
    max-width: $tablet-container-width;
    box-sizing: border-box;
  }

  @include iftablet {
    max-width: $mobile-container-width;
    gap: 24px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.blogDataWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 320px));
  justify-content: center;
  column-gap: 24px;
  row-gap: 30px;

  @include iftablet {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  @include ifmobile {
    grid-template-columns: 1fr;
  }
}

.buttonWrapper {
  justify-self: center;
}
