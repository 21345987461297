@use "../../../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel-heading,
    .panel-body {
      padding: 16px;
    }
  }

  @include ifmobile {
    top: 115px;
    right: auto;
  }
}

.form {
  display: grid;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: $primary-black;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 16px;

  margin-top: 24px;
}

.cancelButton {
  border: 1px solid $white;
}
