// fonts:
// Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap");

// default colors
$white: #ffffff;
$black: #000000;
$red: #fa2828;
$orange: #ffb23f;
$gray: #9b9b9b;

// custom colors
$primary-white: #fcfcfc;
$primary-black: #060814;
$primary-orange: #f2631b;
$primary-red: #a21916;
$custom-black: #222227;
$custom-white: #f4f4f4;
$black-light: #000000b2;
$black-medium: #00000061;
$white-dark: #e8e8e8;
$gray-dark: #474747;
$custom-brown: #ffa72245;
$brown-hover: #5e4624;
$brown-active: #342d25;
$brown-light: rgba(238, 190, 96, 0.46);
$brown-medium: rgba(255, 221, 101, 0.35);
$brown-dark: rgba(105, 69, 27, 0.12);
$green-light: #20c527;
$custom-blue: #232a30;
$custom-green: #edffd7;
$custom-red: #eb4c4b;
$custom-secondary-green: #7ad291;
$custom-orange: #d97916;
$custom-gray: #858585;
$custom-yellow: #d7b46a;

// skins rarity
$uncommon: #5e98d8;
$uncommon-dark: #446c9d;
$rare: #4b69ff;
$rare-dark: #374cb9;
$mythical: #8745ff;
$mythical-dark: #6031bb;
$legendary: #b130c1;
$legendary-dark: #7e228d;
$ancient: #eb4b4b;
$ancient-dark: #a8373b;
$exceedinglyRare: #cbac04;
$exceedinglyRare-dark: #9e8811;
$immortal: #f0ae34;
$immortal-dark: #9c7938;

// shadows
$checkbox-shadow: 0px 0px 0px 1px $red;
$skin-shadow: 0px 0px 25px 0px $black-light;
$modal-shadow: 0px 0px 25px 0px $black-medium;

// gradients
$button-border-gradient: linear-gradient(
  116.69deg,
  $brown-light 38.82%,
  $brown-dark 54.55%,
  $brown-medium 71.26%
);

// fonts
$font-main: "Inter", sans-serif;

// widths
$main-container-width: 1300px;
$tablet-container-width: 1024px;
$mobile-container-width: 768px;
