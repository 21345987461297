@use "./assets/scss/main.scss" as *;

.container {
  height: 100vh;

  color: $primary-white;
}

.appLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}

:global {
  .carousel {
    .control-dots {
      left: -7.5%;

      .dot {
        width: 9px;
        height: 9px;
        position: relative;
        opacity: 1;

        background: $custom-gray;

        &.selected {
          background: $primary-orange;
          border: 2px solid $primary-orange;

          &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 11px;
            height: 11px;
            border: 2px solid $primary-orange;
            top: -5px;
            left: -5px;
          }
        }
      }
      @include ifmobile {
        left: 0;
      }
    }
  }
}
